<template>
  <BCategory
    ref="bsCategory"
    :showFreeShipping="freeShipping.show"
    :layoutTop="`${layoutTop}rem`"
    :fsData="fsData"
    :isPreview="isPreview"  
    :swipeUpText="language?.SHEIN_KEY_PWA_17501"
    :autoFetch="false"
    @markPoint="markPoint"
    @preload="preload"
    @changeQuery="changeRouterQuery"
    @notify="setMonitor"
  >
    <template
      v-if="freeShipping.show"
      #freeshipping
    >
      <AppBff
        v-if="freeShipping.isBff"
        :content="freeShipping.content"
        :context="freeShipping.context"
        :scene-data="freeShipping.scene"
      />
      <AppCcc
        v-else
        :content="freeShipping.content"
        :context="freeShipping.context"
        :scene-data="freeShipping.scene"
      />
    </template>
  </BCategory>
</template>
<script setup>
import { ref, defineProps, computed, onMounted, defineExpose, nextTick } from 'vue'
import { BCategory } from '@shein-aidc/bs-category-mobile-vue2'
import { template, stringifyQueryString } from '@shein/common-function'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import AppBff from 'public/src/pages/components/ccc/home-bff-components/index.vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'
import { categoryRequestParams } from 'public/src/pre_requests/modules/category/index.js'
// import { abtservice } from 'public/src/services/abt/index.js'
import useVuex from './hooks/useVuex'
import useRouter from './hooks/useRouter'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import { setBffLazyImg } from 'public/src/pre_requests/modules/category/preloadImg.js'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'

const props = defineProps({
  layoutTop: {
    type: Number,
    default: 0
  }
})
// const { proxy } = getCurrentInstance()
const { useMapState } = useVuex('category')
const { router, route } = useRouter() 
const { locals, isPreview, activeChannel,topNav, leftContent, rightContent, categoryFreeshippingCcc, bffLanguages } = useMapState(['locals','isPreview', 'topNav','leftContent','rightContent','activeChannel', 'categoryFreeshippingCcc', 'bffLanguages'])
const language = ref({})
const freeshippingData = ref({})

const freeShipping = computed(() => {
  let { freeshippingContent, freeshippingLanguage } = freeshippingData.value || {}
  if(categoryFreeshippingCcc.value?.hasFreeshippingComp){
    // ssr 免邮数据渲染处理
    freeshippingContent = categoryFreeshippingCcc.value?.freeshippingContent
    freeshippingLanguage = categoryFreeshippingCcc.value?.freeshippingLanguage
  }
  const isBff = categoryFreeshippingCcc?.value?.isBff || freeshippingData.value?.isBff || false
  const content = freeshippingContent?.content || []
  const show = content.length > 0
  const context = {
    language: freeshippingLanguage,
    ...locals.value
  }
  const scene = {
    pageFrom: 'category',
    id: freeshippingContent?.id || '',
    pageType: 'category', // 埋点
    sceneName: 'all'
  }
  return { show, content, context, scene, isBff }
})

function getPreloadData(preData){
  const {leftContent,rightContent,topNav,activeChannel} = preData
  const hasBffData = topNav?.length > 0 && leftContent && rightContent
  if(hasBffData){
    const { channel, oneCate } = activeChannel || {}
    const data = {
      channelId: channel?.id,
      oneCate: parseInt(oneCate || 0),
      topNav,
      leftContent,
      rightContent
    }
    return data
  }
  return null
}

function getSSrData(){
  // UG-151202 处理界面回退问题(使用computed方式会导致界面重复被执行)
  const { channelId } = route.query
  const hasSSRData = topNav.value && leftContent.value && rightContent.value
  if(hasSSRData){
    // ssr数据渲染
    const { channel, oneCate } = activeChannel.value || {}
    setBffLazyImg(rightContent.value)
    const data = {
      channelId: channelId || channel?.id,
      oneCate: parseInt(oneCate || 0),
      topNav: topNav.value,
      leftContent: leftContent.value,
      rightContent: rightContent.value
    }
    // console.log('fsData ssr:',data)
    return data
  }
  return null
}
const fsData = ref(getSSrData())

// function getUserSaAbt(keys){
//   const abtest = abtservice.getUserAbtResultForAnalysis(keys)
//   return abtest
// }

function preload(url, type) {
  if (!url) return
  if (type === 'pointerover') {
    preloadGoodsRequest.triggerNotice(url)
  } else {
    preloadGoodsRequest.click(url)
  }
}

function setMonitor(type,item){
  let cate_level = '0'
  let message = 'category header tab click by bs'

  if(type === 'oneCate'){
    cate_level = '1'
    message = 'Left level first category click by bs'
  }else if(type === 'twoCate'){
    cate_level = '2'
    message = 'Right level second category click by bs'
    Monitor.metricCategoryType({
      tags: { 
        page: 'page_category',
        type: item.hrefType
      },
      message
    })
  }
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level,
    },
    message
  })
  
}

const changeRouterQuery = (query) => {
  // const { $router: router, $route: route } = proxy
  // const queryStr = stringifyQueryString({ queryObj: newQuery })
  // console.log('changeRouterQuery:',location.href);
  const newQuery = Object.assign({}, route.query, query)
  router.replace({
    path: route.path,
    query: newQuery
  })
  // router.replace(`${route.path}?${queryStr}`)
}

// function resetBottom(){
//   // 处理浏览器兼容性问题
//   if(bsCategory.value){
//     const rect = bsCategory.value.getBoundRect()
//     const sh = window.innerHeight
//     const diffH = sh - rect.bottom
//     console.log(diffH,sh,rect);
//     if(diffH > 52){
//       // const newb = 50 - (diffH - 50)
//       bsCategory.value.resetBottom(`${100 - diffH}px`)
//     }else{
//       bsCategory.value.resetBottom('50px')
//     }
//   }
// }

const bsCategory = ref(null)
function refreshLayout(){
  if(bsCategory.value){
    bsCategory.value.refreshScroll()
    bsCategory.value.scrollToSelected()
  }
}

async function getCateGoryData(){
  try {
    markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
    const {channelId,oneCate} = route?.query || {}
    const params = categoryRequestParams({
      withI18n: true,
      cateType: 'cat',
      channelId,
      oneCate
    })
    const res = await TMG.useQuickRequest('bffCategory/getCategorys',params)
    markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
    const { categoryFreeshippingCcc, bffLanguages: bfflangs, ...category } = res.data
    markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
    // bsCateData.value = category
    fsData.value = getPreloadData(category)
    language.value = bfflangs
    freeshippingData.value = categoryFreeshippingCcc
    nextTick(() => {
      markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
    })
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  if(!fsData.value){
    await getCateGoryData()
  }else{
    language.value = bffLanguages
  }

  const { leftContent,rightContent, topNav  } = fsData.value || {}
  const status = (leftContent?.contents?.length && rightContent?.contents?.length) ? '1':'0'
  metricPageSuccess({ 
    page: 'page_category',
    status
  })
  if(!topNav?.length) {
    HomeSlslog.slsCompFilter({
      logLevel: 3,
      tag: 'web_client_home_error_total',
      message: 'CategoryDataError',
      reason: 'tabEmpty',
      module: 'TopBar',
    }, false)
  }
  // window.refreshLayout = refreshLayout
  setTimeout(() => {
    appEventCenter.$on('refreshCategoryCrowdInfo',() => {
      if(bsCategory.value){
        bsCategory.value.refreshTopNav()
      }
    })
  })
})

defineExpose({ refreshLayout })
</script>
<style lang="less">
@import url( '@shein-aidc/bs-category-mobile-vue2/style.css');
</style>